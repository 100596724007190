import React, { useEffect, useState } from "react";
import { Wrapper, WrapperDiv } from "./styles";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useOrderContext } from "../../context/order_context";
import { useCartContext } from "../../context/cart_context";
import { Country, State, City } from "country-state-city";

const countries = [Country.getCountryByCode("IN")];
const states = State.getStatesOfCountry("IN");
console.log("all states", states);
const cities = City.getCitiesOfState("IN", "AN");
console.log("all cities", cities);

function ShippingForm({ confirmShipping }) {
  const [citiesDropdown, setCitiesDropdown] = useState(cities)
  const [statesDropdown, setStateDropDown] = useState(states)
  const {
    shipping: {
      name,
      phone_number,
      address: { line1, postal_code, city, state, country },
    },
    updateShipping,
  } = useOrderContext();

  const handleStateSelection = (selectedState) => {
    console.log("selected states", state);
    const selectedStates = statesDropdown.filter(x => x.name === selectedState);
    console.log(selectedStates, "hello sates");
    if (selectedStates.length > 0) {
      const selectedCode = selectedStates[0].isoCode;
      console.log(selectedCode, "cities");
      setCitiesDropdown(City.getCitiesOfState("IN", selectedCode))
    }
  }



  const { cart } = useCartContext();
  console.log(updateShipping, "sttate");

  const handleSubmit = (e) => {
    e.preventDefault();
    const zipRegex = new RegExp("^[1-9][0-9]{5}$");

    if (!name) {
      return toast.error("Enter your Name");
    }
    if (!phone_number || !/^\d{10}$/.test(phone_number)) {
      return toast.error("Enter your phone number");
    }
    if (!line1) {
      return toast.error("Enter your Address");
    }
    if (!postal_code) {
      return toast.error("Enter your Zip Code");
    }
    if (!zipRegex.test(postal_code)) {
      return toast.error("Enter Valid Zip Code");
    }
    if (!city) {
      return toast.error("Enter your City");
    }
    if (!state) {
      return toast.error("Enter your State");
    }
    if (!country) {
      return toast.error("Enter your Country");
    }
    return confirmShipping();
  };

  if (cart.length < 1) {
    return (
      <WrapperDiv className="page">
        <div className="empty">
          <h2>Your cart is empty</h2>
          <Link to="/products"      className="btn submit-btn"
            style={{ margin: "30px",maxWidth:'300px'  }}>
            fill it
          </Link>
        </div>
      </WrapperDiv>
    );
  }

  return (
    <Wrapper className="">
      <div className="container-fluid ">
        <div className="title">
          <h2>Shipping</h2>
        </div>
        <br />
        <form onSubmit={handleSubmit}>
          {/* name */}
          <div className="row">
            <div className="col-md-6">
              <label>Full Name</label>
              <div className="form-control-2">
                <input
                  type="text"
                  name="name"
                  className="input"
                  placeholder="Full name"
                  value={name}
                  onChange={updateShipping}
                />
              </div>
              <label>Address</label>
              <div className="form-control-2">
                <input
                  type="text"
                  name="line1"
                  className="input"
                  placeholder="Address"
                  value={line1}
                  onChange={updateShipping}
                />
              </div>
              <label>State</label>
              <div className="form-control-2">
                <select
                  name="state"
                  className="input sort-input"
                  value={state}
                  onChange={(e) => { updateShipping(e); handleStateSelection(e.target.value) }}
                >
                  <option value="">Select State</option>
                  {states.map((item, index) => {
                    return (
                      <option key={index} value={item.stateCode}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>


              <label>Pincode</label>
              <div className="form-control-2">
                <input
                  type="number"
                  name="postal_code"
                  className="input"
                  placeholder="Zip Code"
                  value={postal_code}
                  onChange={updateShipping}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>Phone Number</label>
              <div className="form-control-2">
                <input
                  type="number"
                  name="phone_number"
                  className="input"
                  placeholder="Phone number"
                  value={phone_number}
                  onChange={updateShipping}
                />
              </div>

              <label>Country</label>
              <div className="form-control-2">
                <select
                  name="country"
                  className="input sort-input"
                  value={country}
                  onChange={updateShipping}
                >
                  <option value="">Select Country</option>
                  {countries.map((item, index) => {
                    return (
                      <option key={index} value={item.countryCode}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <label>City</label>
              <div className="form-control-2">
                <select
                  name="city"
                  className="input sort-input"
                  value={city}
                  onChange={updateShipping}
                >
                  <option value="">Select City</option>
                  {citiesDropdown.map((item, index) => {
                    return (
                      <option key={index} value={item.isoCode}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

            </div>
          </div>
          <br />
          <br />
          <button type="submit" style={{ width: '30%' }} className="btn shipping-btn">
            confirm
          </button>
        </form>
        <br />
        <br />
      </div>
    </Wrapper>
  );
}

export default ShippingForm;
