import React, { useEffect } from "react";
import GoToTop from "../../../GoToTop";

const Policy = () => {
  useEffect(() => {
    console.log("hello scroll");
    var scrollToTop = window.setInterval(function() {
        var pos = window.pageYOffset;
        if ( pos > 0 ) {
            window.scrollTo( 0, pos - 20 ); // how far to scroll on each step
        } else {
            window.clearInterval( scrollToTop );
        }
    }, 16);
  }, []);
  return (
    <>
      <div className="container contact">
        <GoToTop />
        <div className="heading">
          <h4> Privacy </h4>
        </div>
        <p>
          <b> Personal Information We Collect:</b>
        </p>
        <p>
          {" "}
          We collect various types of information from registered users on SRK
          Styles. The personally identifiable information includes Contact
          Details (such as Contact Number and Address), Personal Details (such
          as User Name), Billing Details (including Physical Billing address,
          Payment Method, Transaction Details), User Preferences (such as order
          method preferences, Time-zone, Language), and User Comments (Feedback,
          Complaints). This information is collected to facilitate order
          placement, request information and support, and to make personalized
          product suggestions.
        </p>

        <p>
          Forms on our website and mobile application are utilized to collect
          this information. We receive and store information provided through
          these forms, as well as through other channels such as email,
          telephone, or other communications with our customer service team.
          Internal records are maintained for customer support interactions.
        </p>

        <p>
          <b> How We Use the Information We Collect:</b>
        </p>

        <p>
          {" "}
          The collected information is used to process orders, manage user
          accounts, and communicate with users regarding suggestions, queries,
          product functionalities, special offers, and changes in the Privacy
          Policy. Communication channels include push notifications, messages,
          and calls. Push notifications are employed to confirm orders, provide
          requested information, and offer updates. Email links are provided for
          direct contact, and user feedback may be reviewed and utilized to
          enhance our website, application, products, and services.
        </p>

        <p>
          {" "}
          To process orders, we may share information with credit reference and
          fraud prevention agencies.
        </p>

        <h4> Cookies:</h4>

        <p>
          {" "}
          We do not use cookies to store personal information; instead, cookies
          are used as a browser feature for saving passwords. Cookies assist in
          improving site access and identifying repeat visitors but do not
          collect personal information.
        </p>

        <h4> Security:</h4>

        <p>
          {" "}
          We prioritize the security of user information and employ measures to
          protect against loss, misuse, or alteration. Sensitive information
          submitted online is encrypted, and both online and offline data
          storage environments are secure. Personal information is retained only
          as long as necessary, and we take precautions for proper disposal.
        </p>

        <p>
          <b> Sharing Information with Third Parties:</b>
        </p>

        <p>
          {" "}
          Information may be shared with third parties to provide services on
          our behalf, such as order shipment, credit card processing,
          advertising, surveys, and customer relationship management. We only
          share necessary information for the requested service, and third
          parties are prohibited from using it for other purposes.
        </p>

        <p>
          <b>SRK Styles Management:</b>
        </p>

        <p>
          The "SRK Styles" app is available to all apparel and clothing store
          owners. The app allows owners to manage store details, orders, menus,
          payments, delivery methods, and more. The feature of uploading photos
          using Instagram is provided, and private information is not disclosed
          to third-party apps.
        </p>

        <p>
          <b>Changes in Privacy Policy:</b>
        </p>

        <p>
          {" "}
          We reserve the right to modify this Privacy Policy as needed. Changes
          will be posted on the website, and users are encouraged to review the
          policy periodically. Continued use of the services following policy
          changes constitutes acceptance.
        </p>

        <h4> Contact Details:</h4>

        <p>
          {" "}
          <b>
            {" "}
            For queries, feedback, or concerns about this Privacy Policy,
            contact us at:{" "}
          </b>
        </p>
        <h5>SRK Styles</h5>
        <ul>
          <li> Near Naz public school, Qila Aonla, 243301</li>
          <li> Contact Number: +91-9720106090</li>
          <li> Email: hello.srkstyles@gmail.com</li>
        </ul>
      </div>
    </>
  );
};
export default Policy;
