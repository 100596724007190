import React from 'react';
import { FaShoppingCart, FaUserPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from './styles';
import { useProductsContext } from '../../context/products_context';
import { useCartContext } from '../../context/cart_context';
import { useUserContext } from '../../context/user_context';
import { default_profile_image } from '../../utils/constants';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Cart } from '../../pages';
import NewCart from '../../pages/CartPage/NewCart';

const CartButtons = () => {
  const { currentUser } = useUserContext();
  const { closeSidebar } = useProductsContext();
  const { total_items } = useCartContext();
  const [isOpen, setIsOpen] = React.useState(false)
  
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <Wrapper className='cart-btn-wrapper'>
      <p className='cart-btn' onClick={toggleDrawer}>
        Cart
        <span className='cart-container'>
          <FaShoppingCart />
          <span className='cart-value'>{total_items}</span>
        </span>
      </p>
      {!currentUser ? (
        <Link to='/login' className='auth-btn' onClick={closeSidebar}>
          Login <FaUserPlus />
        </Link>
      ) : (
        <Link to='/profile' className='profile-btn' onClick={closeSidebar}>
          <img
            src={currentUser.photoURL || default_profile_image}
            alt='profile'
          />
        </Link>
      )}
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='right'
        className='cart-drawer'
        size="400px"
      >
        <NewCart 
        onClose={toggleDrawer}
        />
      </Drawer>
    </Wrapper>
  );
};

export default CartButtons;
