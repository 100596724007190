import styled from 'styled-components';

const Wrapper = styled.section`
  img {
    height: 290px;
  }

  .products-container {
    display: grid;
    gap: 2rem 1.5rem;
  }

  @media (min-width: 992px) {
    .products-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1170px) {
    .products-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 600px) {
    .products-container {
      margin-bottom: 10px;
      gap: 0.5rem 0.5rem
    }
  }
`;

export default Wrapper;
