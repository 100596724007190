import styled from 'styled-components';

const Wrapper = styled.section`
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 1rem;
      margin-top: 6px;
      padding: 2px 10px;
      border-left: 4px solid #000; 
    }
  }
  .search-input {
    border-radius: var(--radius);
    border-color: transparent;
    letter-spacing: var(--spacing);
    font-family: 'Nunito Sans', sans-serif;
  }
  .search-input::placeholder {
    text-transform: capitalize;
  }

  button {
    display: block;
    margin: 0.25em 0;
    padding: 0.25rem 0;
    text-transform: capitalize;
    background: transparent;
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif;
    border: none;
    border-bottom: 1px solid transparent;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
    cursor: pointer;
  }
  .active {
    border-color: var(--clr-grey-5);
  }
  .company {
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    padding: 0.25rem;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px
  }
  .colors {
    display: flex;
    align-items: center;
  }
  // .all-color button{
  //   width: 11% !important; 
  //   margin-top: 10px !important;
  // }
  .color-btn {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  .all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .all-btn .active {
    text-decoration: underline;
  }
  .price {
    margin-bottom: 0.25rem;
    font-family: 'Nunito Sans', sans-serif;
  }
 
  .shipping {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    text-transform: capitalize;
    column-gap: 0.5rem;
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;

  }
  .clear-btn {
    background: var(--clr-red-dark);
    color: var(--clr-white);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
    font-family: 'Nunito Sans', sans-serif;
    margin-bottom: 25px;
    margin-top: 10px
  }
  @media (min-width: 768px) {
    .content {
      position: sticky;
      top: 1rem;
    }
  }
`;

export default Wrapper;
