import React from 'react';


const Termsandcondition = () => {
    return (
        <>
            <div className='container'>
                <div className='heading'>
                    <h4>
                        Terms and Condition
                    </h4>
                </div>
                <p><b>These terms must be accepted by you when you use SRK Styles online Application:</b></p>
                <p>
                    You must not accept these terms if:
                    You are not lawfully entitled to use SRK Styles online website or App in the country in which you are located or reside.
                    If you are not of legal age to bind an agreement with us.
                    If any change made to Terms & Conditions:
                    SRK Styles online team can modify Terms & Conditions at any time, at its sole discretion. If SRK Styles online team modifies any content, the team will notify you either through the site or the app. It is crucial that you agree to the modified Terms & Conditions. If you do not agree to be bound by the modified Terms, then you cannot use the services anymore. Our services are evolving over time, and we can change or close any services at any time without notice, at our sole discretion.
                </p>
                <p> <b>Privacy:</b></p>
                <p>  Your privacy is very important to us. We assure you that your private data will not be disclosed anywhere at any cost. If you have any questions or concerns about terms and conditions, please contact us at hello.srkstyles@gmail.com.</p>

                <h4> Legal Activity</h4>

                <li> Do not use SRK Styles online to promote any illegal activities.
                    Harmful Activities</li>

                <p>   Do not distribute content that harms or interferes with the operation of the networks, servers, or other infrastructure of SRK Styles online.
                    Hacking Personal Information</p>

                <p>  Do not access other user’s accounts without their permission. Do not disturb other people’s personal information like email IDs, passwords, play store, or app store credentials without their permission.</p>
                <h3>  NOTE: </h3>
                <p>
                    In case of any illegal activities from a user, we can block their account permanently.
                </p>

                <h4> Refund Policy:</h4>

                <p> For Store Owner:
                    In case of payment made by mistake or any payment-related issues from Google Play Store, we are not entitled to refund any amount. If there is a genuine problem seen in our system, we can look into it and resolve the issue or issue a refund.<p />

                    <p><b> For Customer of Store:</b></p>
                    <p>      In case of payment made by mistake or any payment-related issues for products ordered with SRK Styles online, we are not entitled to refund any amount. The Store Owner will be responsible for issuing a refund to the customer for the placed order in any case.</p>

                    <p><b>  Order Approval:</b></p>

                    <p> SRK Styles online is not responsible for any kind of order approval. Delivery time, Taxes, Delivery Charges, and Delivery times are decided by the store owner. Store owners are only responsible for any updates and changes in extra charges. SRK Styles online may take extra service charges from the customers, which can help us maintain our service/platform. If an order is paid online and canceled by the store, it is refundable. Refund will be settled in 2-6 business days. </p>

                    <p><b> Communication Problems between Customer and Store: </b></p>

                    <p>   In case of misbehavior, miscommunication, or any illegal activities done by a customer and store registered here, we will not be responsible for any such activities as we do not take any proof of their identity.</p>

                    <p> <b> Blocking or Deleting Your Account</b></p>

                    <p>  If we notice any illegal activity, then we have all rights to permanently block your account without any notice and reasons.
                    </p>
                </p>
            </div>
        </>
    )
}
export default Termsandcondition;