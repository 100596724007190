import React from 'react';
import Icon1 from '../../../assets/images/icons/1.png'
import Icon2 from '../../../assets/images/icons/2.png'
import Icon3 from '../../../assets/images/icons/3.png'

const Singleitems = () => {
    return (
        <>
            <div className="feature-area">
                <div className="container mt-30px">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-lm-30px">
                            {/* single item */}
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img src={Icon1} alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4 className="">All India Shipping</h4>
                                    <span className="sub-title"></span>
                                </div>
                            </div>
                        </div>
                        {/* single item */}
                        <div className="col-lg-4 col-md-6 mb-md-30px mb-lm-30px mt-lm-30px">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img src={Icon2} alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4 className="">Card Payments</h4>
                                    {/* <span className="sub-title">Accepted here</span> */}
                                </div>
                            </div>
                        </div>
                        {/* single item */}
                        <div className="col-lg-4 col-md-6 mb-lm-30px">
                            <div className="single-feature">
                                <div className="feature-icon">
                                    <img src={Icon3} alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4 className="">Fast Delivery</h4>
                                    <span className="sub-title"></span>
                                </div>
                            </div>
                            {/* single item */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Singleitems;