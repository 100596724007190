import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { Filters, ProductList, Sort, PageHero } from '../../components';
import useQueryParams from '../../utils/utils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import ScrollToTop from 'react-scroll-to-top';

const ProductsPage = () => {
  const location = useLocation();
  const searchQuery = location.state?.query || '';
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Use searchQuery to filter products or make an API call
    console.log('Search Query:', searchQuery);
  }, [searchQuery]);

  const myParams = useQueryParams();
  console.log(myParams, "myParams");

  useEffect(() => {
    document.title = 'SRK STYLE | Products';
    console.log("hello anas");

    // Detect if the screen width is mobile-sized
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as per your design
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  


  return (
    <main>
       <ScrollToTop smooth />
      <PageHero title='products' />
      <Wrapper className='page'>
        <div className='section-center products'>

          {isMobile && (
            <>
              <button onClick={toggleDrawer} className='filters-btn'>Filters</button>
              <Drawer
                open={isDrawerOpen}
                onClose={toggleDrawer}
                direction="left"
                className="mobile-drawer filters-drawer"

              >
                <Filters myParams={myParams} className="hello"/>
              </Drawer>
            </>
          )}
          {!isMobile && <Filters myParams={myParams} />}
          <div>
            <Sort />
            <ProductList />
          </div>
        </div>
      </Wrapper>
    </main>
  );
};

export default ProductsPage;
