import styled from 'styled-components';
const Wrapper = styled.section`
.primary-btn1{
    background-color: #fb5d5d;
    width: 82px;
    border-radius: 10px;
    padding: 8px 9px 5px 13px;
    margin: 4px -1px 29px;
    color: white;
}
.address  h5{
    color: #fb5d5d;
}
.address a{
    text-decoration:none !important;
    color: black !important;
}
.banner-content h1{
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: 25px;
    color: var(--title-color);
    margin-bottom: 20px;
    line-height: 1.2;
    text-transform: capitalize;
}
`;
export default Wrapper;