import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ProductsProvider } from './context/products_context';
import { FilterProvider } from './context/filter_context';
import { CartProvider } from './context/cart_context';
import { UserProvider } from './context/user_context';
import { OrderProvider } from './context/order_context';

// import './assets/scss/style.scss'
import './assets/css/vendor/slick.css';
import './assets/css/style.css';
import './assets/css/vendor/font.awesome.css';
import './assets/css/vendor/animate.css';
import './assets/css/vendor/nice-select.css';
import './assets/css/vendor/magnific-popup.css';
import './assets/css/vendor/bootstrap.min.css'
import './assets/css/vendor/pe-icon-7-stroke.css'
import './assets/css/plugins/swiper-bundle.min.css'
import './assets/css/plugins/jquery-ui.min.css'
import './assets/css/plugins/venobox.css'

ReactDOM.render(
  <UserProvider>
    <ProductsProvider>
      <FilterProvider>
        <CartProvider>
          <OrderProvider>
            <App />
          </OrderProvider>
        </CartProvider>
      </FilterProvider>
    </ProductsProvider>
  </UserProvider>,
  document.getElementById('root')
);
