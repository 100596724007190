import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "./styles";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  useStripe,
  Elements,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useCartContext } from "../../context/cart_context";
import { useUserContext } from "../../context/user_context";
import { useOrderContext } from "../../context/order_context";
import { formatPrice } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  create_order_razor,
  promo_code_url,
  payment_url as url,
} from "../../utils/constants";
import useRazorpay from "react-razorpay";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const promise = null;

const CheckoutForm = () => {
  const { cart, total_amount, shipping_fee, clearCart } = useCartContext();
  const { shipping, placeOrder } = useOrderContext();
  const { currentUser } = useUserContext();
  const history = useHistory();
  const [Razorpay] = useRazorpay();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedSizeCharts, setSelectedSizeCharts] = useState("");
  const [promoData, setPromoData] = useState(null);
  const [promoError, setPromoError] = useState(null);

  const handleShow = (sizeChart) => {
    setSelectedSizeCharts(sizeChart);
    setShow(true);
  };

  useEffect(() => {
    const fetchPromoData = async () => {
      try {
        const response = await axios.get(promo_code_url);
        setPromoData(response.data);
      } catch (error) {
        setPromoError(error);
      }
    };

    fetchPromoData();
  }, [promo_code_url]);

  console.log(promoData?.promos, promoError, "promodata");

  // STRIPE STUFF
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState(null);

  const handleChange = (item) => {
    setSelectedDiscount(item);
  };
  console.log(selectedDiscount, "selectedDiscount");

  const ResetPromoCode = () => {
    setSelectedDiscount("");
    handleClose();
  };

  const {
    shipping: {
      name,
      phone_number,
      address: { line1, postal_code, city, state, country },
    },
    updateShipping,
  } = useOrderContext();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
  };
  console.log(selectedDiscount)

  const handleOnline = 
    async (e) => {
      const discountPerc = selectedDiscount?.discount || 0
      const finalTotal = total_amount - (total_amount * discountPerc / 100)
      const response = await axios.post(create_order_razor, {
        amount: (finalTotal) * 100,
      });
      console.log(response.data.orderId);

      const options = {
        key: "rzp_live_1aAHLPYwnw5WKF",
        amount: (finalTotal) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "SRK STYLE", //your business name
        description: "",
        image:
          "https://srkstyle.com/static/media/srk-style.92166bff09f9e4439ea7.png",
        order_id: response.data.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: "",
        handler: function (response) {
          if (response.razorpay_payment_id && response.razorpay_order_id) {
            localStorage.setItem(
              "razorpay_payment_id",
              response.razorpay_payment_id
            );
            handleCOD();
          }
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          name: name, //your customer's name
          email: currentUser.email,
          contact: phone_number, //Provide the customer's phone number for better conversion rates
        },
        notes: {
          address:
            line1 +
            "," +
            postal_code +
            "," +
            city +
            "," +
            state +
            "," +
            country,
        },
        theme: {
          color: "#ED1E2C",
        },
      };

      const rzpay = new Razorpay(options);

      rzpay.on("payment.failed", function (response) {
        toast.error(`${response.error.description} : ${response.error.reason}`);
      });
      rzpay.open();
    }

  const handleCOD = () => {
    const discountPerc = selectedDiscount?.discount || 0
    const finalTotal = total_amount - (total_amount * discountPerc / 100)
    setSucceeded(true);
    placeOrder(finalTotal);
    clearCart();
    history.push("/orders");
  };
  const discountPerc = selectedDiscount?.discount || 0
  const finalTotal = total_amount - (total_amount * discountPerc / 100)
  console.log(finalTotal, 'finalTotal--->')
  return (
    <div>
      {succeeded ? (
        <article>
          <h4>Thank You!</h4>
          <h4>Your payment was successfull</h4>
          <h4>Redirecting to Order page shortly</h4>
        </article>
      ) : (
        <article>
          <h4 style={{ marginTop: "20px" }}>
            Hello,
            {currentUser &&
              (currentUser?.displayName
                ? currentUser.displayName
                : currentUser.email)}
          </h4>
          <p>Your total is {formatPrice(shipping_fee / 100 + total_amount)}</p>
          {discountPerc > 0 && <p style={{color:'green'}}>After discount {formatPrice(shipping_fee / 100 + finalTotal)}</p>}

          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className="cart_button"
              style={{ marginTop: "10px", marginBottom: "10px", zoom: 1.5 }}
            >
              <Link
                to="/cart"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  border: "2px solid silver",
                  padding: "2px 10px",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                <i className="fa fa-shopping-cart" /> View cart
              </Link>
            </div>
            <div
              className="cart_button"
              style={{ marginTop: "10px", marginBottom: "10px", zoom: 1.5 }}
            >
              <button
                style={{
                  background: "white",
                  color: "#000",
                  border: "2px solid silver",
                  padding: "2px 10px",
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
                onClick={() => handleShow()}
              >
                {selectedDiscount ? selectedDiscount.code : "Apply Promo"}
              </button>
            </div>
          </div>
          <br />
        </article>
      )}
      <form id="payment-form" onSubmit={handleSubmit}>
        <button
          onClick={handleOnline}
          style={{ backgroundColor: "#fb5d5d", borderRadius: "8px" }}
        >
          Pay Online
        </button>
        Free shipping on online Payment
        <br />
        <button
          onClick={handleCOD}
          style={{ backgroundColor: "#fb5d5d", borderRadius: "8px" }}
        >
          Cash On Delivery
        </button>
        <p
          className={`${succeeded ? "result-message" : "result-message hidden"
            }`}
        >
          Payment succeeded, your items will arrive at your doorstep soon.
        </p>
      </form>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>Promo Code</Modal.Title>
          <i
            class="fa fa-close"
            style={{ fontSize: "24px" }}
            onClick={handleClose}
          ></i>
        </Modal.Header>
        <Modal.Body className="promo-code-modal">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Apply Promo</Form.Label>
            <Form.Control
              type="text"
              value={selectedDiscount ? selectedDiscount : ""}
              placeholder="Type your Promo code"
            />
          </Form.Group>
          <p className="text-center">OR</p>
          <h3>Choose from below</h3>
          <Form className="promo-code">
            {promoData?.promos?.map((item, index) => (
              <div className="d-flex justify-content-between">
                <Form.Check
                  type="radio"
                  label={item.code}
                  name="discountOptions"
                  value={item.code}
                  checked={selectedDiscount?.code === item.code}
                  onChange={() => handleChange(item)}
                />
                <h4>{item.discount}% OFF</h4>
              </div>
            ))}
          </Form>
          {/* <Form className="promo-code">
            <div className="d-flex justify-content-between">
              <Form.Check
                type="radio"
                id="winter-10-checkbox"
                label="WINTER10"
              />
              <h4>10% OFF</h4>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Check
                type="radio"
                id="winter-10-checkbox"
                label="WINTER10"
              />
              <h4>10% OFF</h4>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Check
                type="radio"
                id="winter-10-checkbox"
                label="WINTER10"
              />
              <h4>10% OFF</h4>
            </div>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => ResetPromoCode()}>
            Reset
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const StripeCheckout = () => {
  return (
    <Wrapper>
      <Elements stripe={promise}>
        <CheckoutForm />
      </Elements>
    </Wrapper>
  );
};

export default StripeCheckout;
