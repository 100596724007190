import React, { useState } from "react";
import Wrapper from "./styles";
import { formatPrice } from "../../utils/helpers";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

import { Link } from "react-router-dom";
import { useCartContext } from "../../context/cart_context";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const Product = (product) => {
  const {
    image,
    name,
    price,
    id,
    stock,
    colors,
    sizes,
    salePrice,
    sizeCharts,
  } = product;
  console.log(product, sizeCharts, "product anas");
  const { addToCart } = useCartContext();
  const [mainColor, setMainColor] = useState(colors[0]);
  const [mainSize, setMainSize] = useState(sizes[0]);
  const [amount, setAmount] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedSizeCharts, setSelectedSizeCharts] = useState("");

  const handleShow = (sizeChart) => {
    setSelectedSizeCharts(sizeChart);
    setShow(true);
  };

  console.log(selectedSizeCharts, "s charts");
  return (
    <Wrapper className="product-border">
      <div className="container">
        <img src={image} alt="main" />
        <Link to={`/products/${id}`} className="link">
          <BsFillEyeFill />
        </Link>
      </div>
      <div className="product-card-pd">
        <footer>
          <h5>{name}</h5>
        </footer>
        <p style={{ color: "#ff7000" }}>
          {salePrice > 0 ? formatPrice(salePrice) : price}
          {/* {formatPrice(price)} */}
          {price && (
            <small
              style={{
                textDecoration: "line-through",
                marginLeft: "10px",
                color: "#888888",
              }}
            >
              {price > 0 ? formatPrice(price) : ""}
            </small>
          )}
        </p>
        <div className="size">
          {sizes?.map((size, index) => {
            return (
              <button
                className={
                  stock[index] > 0
                    ? size === mainSize
                      ? "selected-size"
                      : "stock-btn black"
                    : "out-of-stock silver"
                }
                style={{ color: Number(stock[index]) > 0 ? "black" : "silver" }}
                onClick={() =>
                  Number(stock[index]) > 0
                    ? setMainSize(size)
                    : toast.error(`${size} not in stock!`)
                }
              >
                {size}
              </button>
            );
          })}
        </div>
      </div>

      {/* <p onClick={() => handleShow(sizeCharts)} style={{textAlign: "end"}}>Size Chart</p>

      <div className="product-btn-section add-cart">
        <button
          className="add-to-cart-btn add mobile-margin-top"
          onClick={() => {
            addToCart(id, mainColor, mainSize, amount, product);
            toast.success(`${product.name}-${mainSize} added to cart!`);
          }}
        >
          Add to Cart
        </button>
        <button className="add-to-cart-btn buy-now mobile-margin-top-zero">
          <Link to={`/products/${id}`} style={{ textDecoration: "none" }}>
            Buy Now{" "}
          </Link>
        </button>
      </div> */}
      {/* <div className="load-more-items text-center mt-30px0px" data-aos="fade-up">
        <a href="#" className="btn btn-lg btn-primary btn-hover-dark m-auto">
          {" "}
          Load More <i className="fa fa-refresh ml-15px" aria-hidden="true" />
        </a>
      </div> */}
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>Size Chart</Modal.Title>
          <i
            class="fa fa-close"
            style={{ fontSize: "24px" }}
            onClick={handleClose}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedSizeCharts} alt="" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default Product;
