import React, { useState, useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../../../context/user_context";
import { Button } from "bootstrap";
import useMounted from "../../../../src/hooks/useMounted";
import { Form } from "react-bootstrap";

const Loginregister = () => {
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mounted = useMounted();
  const { loginUser, signInWithGoogle } = useUserContext();
  const history = useHistory();
  const location = useLocation();

  function togglePasswordVisibility() {
    setVisible(!visible);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error("Please enter e-mail");
    }

    if (!password) {
      return toast.error("Please enter password");
    }

    setIsSubmitting(true);
    loginUser(email, password)
      .then((res) => {
        history.push(location.state?.from ?? "/");
      })
      .catch((err) => {
        toast.error(`Error: ${err.message}`);
      })
      .finally(() => mounted.current && setIsSubmitting(false));
  };
  return (
    <>
      <div className="container">
        <div className="login-form ">
          <form onSubmit={handleSubmit}>
            <br />
            <h1>Login</h1>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="input"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="input-icon">
                <i className="fa fa-envelope" />
              </span>
            </div>
            <div className="form-group">
              <input
                type={!visible ? "password" : "text"}
                name="password"
                className="input"
                placeholder="Password"
                value={password}
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="input-icon">
                <i className="fa fa-lock" />
              </span>
              {/* <div onClick={togglePasswordVisibility} className='togglebtn'>
              {!visible ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
            </div> */}
            </div>
            <div className="forget-link">
              <div className="form-group">
                <Form.Check
                  inline
                  label="Remember "
                  name="group1"
                  type="checkbox"
                  // id={`inline-${type}-1`}
                />
              </div>
              <div>
                
                <Link to="/forgot-password">Forget Password?</Link>
              </div>
            </div>
            <button type="submit" className="login-btn" disabled={isSubmitting}>
              Login
            </button>

            <div className="register-1">
              <h6>Not a member?</h6>
              <Link to="/register" className="link">
                Register Now
              </Link>
            </div>
            <div className="seperator">
              <span>or</span>
            </div>
            {/* <a className="button button--social-login button--google" href="#">
              <i className="icon fa fa-google" />
              Login With Google
            </a> */}
            <button
              type="button"
              className="button"
              disabled={isSubmitting}
              onClick={() => {
                signInWithGoogle()
                  .then((user) => {
                    history.push("/");
                  })
                  .catch((err) => {
                    toast.error(`Error: ${err.message}`);
                  });
              }}
            >
              <i
                className="icon fa fa-google"
                style={{ padding: "5px 12px 0px" }}
              />
              Sign in with Google
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Loginregister;
