import React from "react";
import Wrapper from "./styles";
import { useCartContext } from "../../context/cart_context";
import { useUserContext } from "../../context/user_context";
import { formatPrice } from "../../utils/helpers";
import { Link } from "react-router-dom";

const CartTotals = (props) => {
  const { currentUser } = useUserContext();
  const { total_amount, shipping_fee } = useCartContext();

  return (
    <>
      <Wrapper>
        <div>
          <article>
            <h5>
              subtotal : <span>{formatPrice(total_amount)}</span>
            </h5>
            <p>
              shipping fee : <span>{formatPrice(shipping_fee/100)}</span>
            </p>
            <hr />
            <h4>
              order total :{" "}
              <span>{formatPrice(total_amount + shipping_fee/100)}</span>
            </h4>
          </article>
        </div>
      </Wrapper>
      
    </>
  );
};

export default CartTotals;
