import React from "react";
import Wrapper from "./styles";
import OrderItem from "../OrderItems/";
import {
  getOrderStatusColor,
  formatAddress,
  formatPrice,
} from "../../utils/helpers";

const isoToLocalDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleDateString("en-US", options);
};

const OrderContent = ({
  paymentInfo,
  orderItems,
  totalPrice,
  orderStatus,
  user: { name },
  shippingInfo,
  shippingPrice,
  createdAt,
  order,
  _id,
}) => {
  const statusColor = getOrderStatusColor(orderStatus);
  return (
    <Wrapper className="section section-center">
       {orderItems.map((item) => {
        return <OrderItem key={item._id + item.color} {...item} />;
      })}
      <div className="order-info">
        <h5 className="order-status">
          Status: <span style={{ color: statusColor }}>{orderStatus}</span>
        </h5>
        <h5 className="payment-status">
          Payment: <span>{paymentInfo.status}</span>
        </h5>
        <h5 className="shipping-fee">
          Shipping: <span>{formatPrice(shippingPrice)}</span>
        </h5>
        <h5 className="order-total">
          Order Total: <span>{formatPrice(totalPrice)}</span>
        </h5>
        <h5 className="order-total">
          Order ID: <span>{_id}</span>
        </h5>
        <h5 className="order-total">
          Ordered Date: <span>{isoToLocalDate(createdAt)}</span>
        </h5>
        <h5 className="order-total">
          Customer Mobile: <span>{shippingInfo.phoneNumber}</span>
        </h5>
      </div>
      <div className="delivery-info">
        <h5>Delivery: </h5>
        <p>
          {name}, {shippingInfo.phoneNumber}
        </p>
        <p>{formatAddress({ shippingInfo })}</p>
      </div>
     
    </Wrapper>
  );
};

export default OrderContent;
