import styled from 'styled-components';

const Wrapper = styled.section`
  margin-top: 1rem;
  margin-bottom: 1rem;
  // display: flex;
  // justify-content: center;
  article {
    border: 1px solid var(--clr-grey-8);
    border-radius: var(--radius);
    padding: 0.5rem;
  }
  @media only screen and (max-width: 400px) {
    article {
      padding: 1.5rem .5rem;
    }
  }
  h4,
  h5,
  p {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    text-transform: capitalize;
    margin-bottom: 0.5rem;
    font-size: 14px;
  }
  h4 {
    margin-top: 0.5rem;
    font-size: 1rem
  }

  hr {
    margin: 0.5rem 0;
  }
  @media (min-width: 776px) {
  
  }
  .btn {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    font-weight: 700;
  }
`;

export default Wrapper;
