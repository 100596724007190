import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Spinner } from "react-bootstrap";
// import mapIcon from '../../assets/google-maps.png';
// import callIcon from '../assets/telephone.png'
import { Link } from "react-router-dom";
import Wrapper from './styles';
import { contact_us_url } from "../../utils/constants";




const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = () => {
    const obj = {
      "fullName": fullName,
      "contactNumber": number,
      "email": email,
      "subject": subject,
      "message": message
  };
    if (fullName.length === 0) {
      setNameError("Fullname is required.");
      return toast.error('Fullname is required.')
    }
    if (email.length === 0) {
      setEmailError("Email is required.");
      return toast.error('Email is required.')
    }
    if (number.length === 0) {
      setNumberError('Number is required.')
      return toast.error('Number is required.')
    }
    if (subject.length === 0) {
      setSubjectError('Subject is required.')
      return toast.error('Subject is required.')
    }
    if (message.length === 0) {
      setMessageError('Message is required.')
      return toast.error('Message is required.')
    }
    setLoading(true)
    axios.post(`${contact_us_url}`, obj)
      .then((res) => {
        console.log("hello anas");
        if (res.data.error) {
          toast.error(res.data.error)
        }
        if (res.data.success === true) {
          toast.success(res.data.message);
          setEmail("");
          setFullName("");
          setSubject("");
          setMessage("");
          setNumber("")
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false)
      });
  }

  return (
    <>
    <Wrapper>
      <div className="inner-page-banner">
        <div className="banner-wrapper container">
          <div className="container-fluid">
            {/* <ul id="breadcrumb-list" className="breadcrumb-list">
              <li className="breadcrumb-item">
                <Link to="/"> Home </Link>
              </li>
              <li className="active">Contact Us</li>
            </ul> */}
            <div className="banner-main-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-7  d-flex align-items-center">
                  <div className="banner-content">
                    <h1>Contact Us</h1>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 d-lg-flex d-none align-items-center justify-content-end">
                  <div className="banner-img">
                    <img
                      src="../wp-content/plugins/drivco-core/inc/theme-options/images/breadcrumb/breadcrumb-right-img.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container contact-us-page">
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control type="text" placeholder="Enter Full Name*" value={fullName} onChange={(e) => setFullName(e.target.value)} />
            </Form.Group>


            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Contact Number*</Form.Label>
              <Form.Control type="number" maxlength="10" placeholder="Contact Number*" value={number} onChange={(e) => {if(e.target.value.length > 10 ){return}setNumber(e.target.value)}} />
            </Form.Group>

          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject*</Form.Label>
              <Form.Control type="text" placeholder="Subject/Reason *" value={subject} onChange={(e) => setSubject(e.target.value)} />
            </Form.Group>

          </div>
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message*</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Enter Message*" value={message} onChange={(e) => setMessage(e.target.value)} />
        </Form.Group>
        <small style={{ color: "red" }}>{messageError}</small>
        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ display: "flex" }}>
                    <Form.Check aria-label="option 1" />
                    <Form.Label style={{ marginLeft: "10px" }}> I accept Privacy Policy.</Form.Label>
                </Form.Group> */}
        <button disabled={isLoading} onClick={handleSubmit} className="primary-btn1">{isLoading ? <Spinner animation="border" /> : 'Submit'}</button>

      </div>
      <div className="container pd-4">
        <div className="row">
          <div className="col-md-5">
            <div className="address">
              {/* <img src={mapIcon} width={30} /> */}
              <div>
                <h5>Address</h5>
                <p>Qila saray Aonla Bareilly 243301,India.</p>
              </div>
            </div>
            <div className="address">
              {/* <img src={callIcon} width={30} /> */}

              <div>
                <h5>Contact Numbers</h5>
                <h6>
                  <a href="tel:+917992990999">+917409055341</a>
                </h6>
                {/* <h6>
                  <a href="tel:+919009005165">+919009005165</a>
                </h6> */}
              </div>
            </div>
          </div>
          {/* <div className="col-md-7">
            <div>
              <iframe
                title="Google Maps Embed"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d112099.22028771536!2d77.22587421666645!3d28.596757679464393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s6%2F4A%20Ground%20Pkt-B%2C%20Ashok%20Vihar%2C%20New%20Delhi%2C%20Delhi%2011052%2C%20India!5e0!3m2!1sen!2sin!4v1707590462791!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div> */}
        </div>
      </div>
      </Wrapper>
    </>
  )
}

export default Contact;
