import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  div {
    min-width: 300px;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .form-control {
      margin-bottom: 1.25rem;
      background: hsl(210, 36%, 96%);
      padding: 0rem !important;
      h5 {
        margin-bottom: 0.5rem;
      }
    }

    // start
    .input{
      width: 100%;
    padding: 5px;
    height: 56px;
    border-radius: 7px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 15px;
    padding-left: 40px;
    }
    .input-icon{
      font-size: 15px;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    height: 100%;
    padding-left: 35px;
    color: #666;font-size: 15px;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    height: 100%;
    padding-left: 15px;
    color: #666;
    }
    // end
    .input::placeholder {
      text-transform: capitalize;
    }
    .links {
      width: 100%;
      margin-top: 1.25rem;
      display: flex;
      justify-content: center;
    }
    .link {
      font-size: 1rem;
      color: var(--clr-primary-1);
      text-transform: capitalize;
    }
    .forgot-button{
      border-radius: 5px;
    width: 100%;
    background-color: #fb5d5d;
    padding: 9px 32px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    height: 44px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    }
    .seperator {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 1.25rem;
      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.25rem;
        background: white;
      }
      hr {
        width: 100%;
      }
    }
    .forgot-btn {
      
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width:767px) {
    div {
      width: 100%;
      padding: 0rem !important;
    }

  }
`;

export default Wrapper;
