import React, { useState, useEffect } from "react";
import image from "../Navbar/imges/srk-style.png";
import { useProductsContext } from "../../context/products_context";
import { useUserContext } from "../../context/user_context";
import "./Index.css";
import GoToTop from "../../GoToTop";
import { Link, useHistory, useLocation } from "react-router-dom";
import useMounted from "../../hooks/useMounted";
import Button from "../../components/Button";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CardDrawer from "../CardDrawer/CardDrawer";
import WishlistDrawer from "../WishlistDrawer/WishlistDrawer";
import { useCartContext } from "../../context/cart_context";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const { currentUser } = useUserContext();
  const userAccessToken = currentUser?.accessToken;
  console.log(
    currentUser?.displayName,
    currentUser,
    userAccessToken,
    "current user header"
  );
  const { openSidebar } = useProductsContext();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const history = useHistory();
  const location = useLocation();
  const mounted = useMounted();
  const { loginUser, signInWithGoogle } = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { cart } = useCartContext();
  console.log(cart.length, "hello cart length");

  const toggleCloseDrawer = () => {
    console.log("hello cart open anas");
    setIsOpen(false);
  };

  const [isOpenCart, setOpenCart] = React.useState(false);

  const handleCartDrawer = () => {
    setOpenCart(true);
  };
  const handleCloseCartDrawer = () => {
    setOpenCart(false);
  };

  const [isOpenWishlist, setOpenWishlist] = React.useState(false);

  const handleWishlistDrawer = () => {
    setOpenWishlist((prevState) => !prevState);
  };
  const handleCloseWishlistDrawer = () => {
    setOpenWishlist(false);
  };
  const onClose = () => {
    console.log("ali");
  };

  const [searchText, setSearchText] = useState('');

  // const handleSearch = () => {
  //   if (searchText.trim()) {
  //     history.push('/products', { query: searchText });
  //     handleClose();
  //   }
  // };

  const handleSearch = () => {
    if (searchText.trim()) {
      const queryParams = {
        category: searchText,
      };
      const searchParams = new URLSearchParams(queryParams).toString();
      history.push({
        pathname: "/products",
        search: `?${searchParams}`,
      });
      handleClose();
      setOpen(false)
    }
  };

  return (
    <>
      <GoToTop />
      <header>
        {/* <div className="header-to-bar ">
          {" "}
          HELLO EVERYONE! 25% Off All Products{" "}
        </div> */}
        <div className="header-main sticky-nav hide-in-mobile">
          <div className="header-padding position-relative">
            <div className="row ">
              {/* <Link to='/' style={{textDecoration:'none'}} className="padding-zero"> */}
              <div className="col-auto align-self-center logo">
                <Link to="/">
                  <img src={image} alt="image"></img>
                </Link>
                <Link to="/" style={{ textDecoration: "none" }}>
                  {" "}
                  <h3>SRKSTYLE</h3>
                </Link>
              </div>
              {/* </Link> */}
              <div className="col align-self-center d-none d-lg-block">
                <div className="main-menu">
                  <ul>
                    <li className="dropdown position-static">
                      <Link to="/products">Shop</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    {/* <li>
                      <a href="contact.html">Return & Exchange</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* Header Action Start */}
              <div className="col col-lg-auto align-self-center pl-0">
                <div className="header-actions">
                  {userAccessToken ? (
                    <Link to="/profile" className="link">
                      <img
                        src={currentUser?.photoURL}
                        style={{
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </Link>
                  ) : (
                    <Link to="/login" className="link hello">
                      Sign in
                    </Link>
                  )}
                  <Button
                    variant="primary"
                    className="pe-7s-search"
                    onClick={onOpenModal}
                  ></Button>


                  {/* <Modal
                    show={show}
                    onHide={ModalClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form
                        className="navbar-form position-relative"
                        role="search"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                        <Button type="submit" className="submit-btn">
                          <i className="pe-7s-search" onClick={handleSearch} />
                        </Button>
                      </form>
                    </Modal.Body>
                  </Modal> */}

                  <Modal open={open} onClose={onCloseModal} center>
                    <h4>Search</h4>
                    <br />
                    <form
                      className="navbar-form position-relative"
                      role="search"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSearch();
                      }}
                    >
                      <div className="form-group" style={{ display: 'inline-flex' }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button type="submit" style={{ padding: '1px 2px 3px 4px' }} className="submit-btn">
                          <i className="pe-7s-search" onClick={handleSearch} />
                        </Button>
                      </div>

                    </form>
                  </Modal>

                  {/* Single Wedge End */}
                  {/* Single Wedge Start */}
                  {/* <Link to="" className="header-action-btn offcanvas-toggle">
                    <button onClick={handleWishlistDrawer}>
                      {" "}
                      <i className="pe-7s-like" />
                    </button>
                  </Link> */}
                  {/* Single Wedge End */}
                  <Link
                    to="#"
                    className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0"
                  >
                    <button onClick={handleCartDrawer}>
                      {" "}
                      <i className="pe-7s-shopbag" />
                    </button>
                    <span className="header-action-num">{cart.length}</span>
                  </Link>

                  <div className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none"></div>

                  <button onClick={toggleDrawer}>
                    {" "}
                    <i className="pe-7s-menu" />
                  </button>
                  <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction="left"
                    className="bla bla bla"
                  >
                    <div>
                      <button
                        className="offcanvas-close"
                        onClick={toggleCloseDrawer}
                      >
                        <span>&#215;</span>
                      </button>
                      <div className="mobile-menu">
                        <ul>
                          <Link to="/">
                            {" "}
                            <li>Home</li>
                          </Link>
                          <li>
                            <Link to="/products">Shop</Link>
                          </li>
                          <li>
                            <Link to="/about">About us</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact Us</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="offcanvas-social mt-auto">
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/" target="_blank">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#https://x.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJteCI6IjIifQ%3D%3D%22%7D"
                              target="_blank"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.google.com/webhp?hl=en&sa=X&ved=0ahUKEwjUxeDSuZaGAxWgVWwGHUFBDewQPAgJ"
                              target="_blank"
                            >
                              <i className="fa fa-google" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/" target="_blank">
                              <i className="fa fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/"
                              target="_blank"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Drawer>

                  {/* <i className="pe-7s-menu" /> */}
                </div>
                {/* Header Action End */}
              </div>
              <CardDrawer
                isOpenCart={isOpenCart}
                handleCloseCartDrawer={handleCloseCartDrawer}
              />

              <WishlistDrawer
                isOpenWishlist={isOpenWishlist}
                handleCloseWishlistDrawer={handleCloseWishlistDrawer}
              />
            </div>
          </div>
        </div>
        <div className="header-padding position-relative hide-in-desktop">
          <div className="mobile-navbar sticky-nav header-padding">
            {userAccessToken ? (
              <Link to="/profile" className="link">
                <img
                  src={currentUser?.photoURL}
                  style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                />
              </Link>
            ) : (
              <Link to="/login" className="link sign-in-mobile">
                Sign in
              </Link>
            )}
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              className="padding-zero"
            >
              <div className="col-auto align-self-center logo">
                <img src={image} alt="image"></img>
                <h3>SRKSTYLE</h3>
              </div>
            </Link>
            <div>
              <div className="col col-lg-auto align-self-center pl-0">
                <div className="header-actions">
                  <Link
                    to="#"
                    className="header-action-btn"
                    onClick={onOpenModal}
                    data-bs-toggle="modal"
                  // data-bs-target="#searchActive"
                  >
                    <i className="pe-7s-search" />
                  </Link>
                  <Link
                    to="#"
                    className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0"
                  >
                    <button onClick={handleCartDrawer}>
                      {" "}
                      <i className="pe-7s-shopbag" />
                    </button>

                    <span className="header-action-num">{cart.length}</span>
                  </Link>

                  <div className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none"></div>

                  <button onClick={toggleDrawer}>
                    {" "}
                    <i className="pe-7s-menu" />
                  </button>
                  <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction="left"
                    className="bla bla bla"
                  >
                    <div>
                      <button
                        className="offcanvas-close"
                        onClick={toggleCloseDrawer}
                      >
                        <span>&#215;</span>
                      </button>
                      <div className="mobile-menu">
                        {userAccessToken && (
                          <div className="mobile-user-avatar">
                            <div class="avatar">
                              <div class="w-10 rounded-full ring ring-offset-base-50 ring-offset-10">
                                <Link
                                  to="/profile"
                                  onClick={toggleCloseDrawer}
                                  className="link"
                                >
                                  <img
                                    src={currentUser?.photoURL}
                                    style={{
                                      borderRadius: "50%",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                </Link>
                              </div>
                            </div>
                            <div>
                              <Link to="/profile" onClick={toggleCloseDrawer}>
                                {" "}
                                <p className="text-white font-md">
                                  {currentUser?.displayName}
                                </p>
                              </Link>
                            </div>
                          </div>
                        )}
                        <ul>
                          <li>
                            <Link to="/" onClick={toggleCloseDrawer}>
                              {" "}
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to="/products" onClick={toggleCloseDrawer}>
                              Shop
                            </Link>
                          </li>
                          <li>
                            <Link to="/about" onClick={toggleCloseDrawer}>
                              About us
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact" onClick={toggleCloseDrawer}>
                              Contact{" "}
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/contact" onClick={toggleCloseDrawer}>
                              Logout{" "}
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                      <div className="offcanvas-social mt-auto">
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/" target="_blank">
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#https://x.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJteCI6IjIifQ%3D%3D%22%7D"
                              target="_blank"
                            >
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.google.com/webhp?hl=en&sa=X&ved=0ahUKEwjUxeDSuZaGAxWgVWwGHUFBDewQPAgJ"
                              target="_blank"
                            >
                              <i className="fa fa-google" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/" target="_blank">
                              <i className="fa fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/"
                              target="_blank"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Drawer>
                </div>
                {/* Header Action End */}
              </div>
              <CardDrawer
                isOpenCart={isOpenCart}
                handleCloseCartDrawer={handleCloseCartDrawer}
              />

              <WishlistDrawer
                isOpenWishlist={isOpenWishlist}
                handleCloseWishlistDrawer={handleCloseWishlistDrawer}
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
