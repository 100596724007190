import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { domain } from "../../../utils/constants";
import { Loading } from "../../../components";

const Newarrivals = () => {

  const [recentProducts, setRecentProducts] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  async function fetchProducts(limit = 9, createdAtFilter) {
    const baseUrl = `${domain}/api/products/recents`; // Replace with your actual API endpoint

    let url = `${baseUrl}`; // Add limit parameter

    // Add createdAt filter if provided
    if (createdAtFilter) {
      const startDate = new Date(createdAtFilter).toISOString(); // Convert date to ISO format
      url += `&createdAt=${startDate}`;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching products: ${response.statusText}`);
      }
      const data = await response.json();
      return data; // Return the parsed JSON data
    } catch (error) {
      console.error('Error fetching products:', error);
      // Handle errors appropriately in your application (e.g., display an error message to the user)
      return null; // Or return some error object to indicate failure
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchProducts().then(response => {
      if (response.success) {
        setLoading(false)
        setRecentProducts(response.data)
      }
    }).catch((error) => {
      setLoading(false)
      setError(String(error))
    })
  }, [])

 

  return (
    <>
      {isLoading? <Loading/> :
        <div className="product-area pt-30px container">
          <div className="container padding-zero">
            <div className="row">
              <div className="col-lg col-md col-12">
                <div className="section-title mb-0">
                  <h2 className="title">#New Arrivals</h2>
                </div>
              </div>
            </div>
            <div className="row pt-30px">
              <div className="col">
                <div className="row">
                  {recentProducts.map(product => {
                    return <div
                      className="col-lg-4 col-xl-3 col-md-6 col-6 mb-30px"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="product">
                        <div className="thumb">
                          <Link to={`/products/${product.id}`} className="image">
                            {product.image &&
                              <>
                                <img
                                  height='250'
                                  style={{ objectFit: 'fill' }}
                                  src={product.image}
                                  alt="Product"
                                />
                                <img
                                  className="hover-image"
                                  style={{ objectFit: 'fill' }}
                                  height='250'
                                  src={product.image}
                                  alt="Product"
                                />
                              </>

                            }
                            {product.images &&
                              <>
                                <img
                                  src={product.images[0]}
                                  alt="Product"
                                />
                                <img
                                  className="hover-image"
                                  src={product.images[0]}
                                  alt="Product"
                                />
                              </>

                            }
                          </Link>
                          <span className="badges">
                            <span className="new">New</span>
                          </span>
                          <Link to={`/products/${product.id}`} style={{ textDecoration: 'none', zoom: 1.2 }} className="add-to-cart">
                            Buy Now
                          </Link>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <a >
                              {product.name}
                            </a>
                          </h5>
                          <span className="price">
                            <h5 style={{color:'#FD4969'}}>₹{product.saleprice ? product.salePrice : product.price}</h5>
                          </span>
                        </div>
                      </div>
                    </div>
                  })}
                </div>
                <div className="load-more">
                  <Link to="/products" className="btn btn-lg btn-primary btn-hover-dark ">
                    {" "}
                    See More{" "}
                    <i className="fa fa-arrow-right ml-15px" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default Newarrivals;
