import styled from 'styled-components';

const Wrapper = styled.article`

    border: 1px solid #ced4da;
    border-radius: 8px;

  .container {
    position: relative;
    padding: 0px;
    border-radius: var(--radius);
  }
  img {
    width: 100%;
    display: block;
    object-fit: fill;
    border-radius: var(--radius);
    transition: var(--transition);
  }


  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--clr-primary-5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: var(--clr-white);
    }
  }
  .container:hover img {
    opacity: 0.5;
  }
  .container:hover .link {
    opacity: 1;
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  footer h5 {
    font-size: 16px;
  }

  footer p {
    margin-bottom: 0;
    font-weight: 400;
  }

  footer p {
    color: var(--clr-primary-5);
    letter-spacing: var(--spacing);
  }
`;

export default Wrapper;
