import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/order_reducer';
import {
  UPDATE_SHIPPING_DETAILS,
  GET_ORDERS_BEGIN,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
} from '../actions';
import { useUserContext } from './user_context';
import { useCartContext } from './cart_context';
import { toast } from 'react-toastify';
import axios from 'axios';
import { get_order_url, create_order_url } from '../utils/constants';

const initialState = {
  orders_loading: false,
  orders_error: false,
  orders: [],
  shipping: {
    name: '',
    address: {
      line1: '',
      postal_code: '',
      city: '',
      state: '',
      country: '',
    },
  },
};

const OrderContext = React.createContext();

export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentUser } = useUserContext();
  const { cart, total_amount, shipping_fee } = useCartContext();

  const fetchOrders = async (url) => {
    dispatch({ type: GET_ORDERS_BEGIN });
    try {
      const response = await axios.post(url, {
        email: currentUser.email,
      });
      const orders = response.data;
      dispatch({ type: GET_ORDERS_SUCCESS, payload: orders.data });
    } catch (error) {
      dispatch({ type: GET_ORDERS_ERROR });
    }
  };


  const placeOrder = async (finalAmount) => {
    const buyNowOrder = localStorage.getItem('buy_now_order_id')
    console.log(buyNowOrder, "hellor order");
    const shippingInfo = {
      address: state.shipping.address.line1,
      city: state.shipping.address.city,
      state: state.shipping.address.state,
      country: state.shipping.address.country,
      pinCode: state.shipping.address.postal_code,
      phoneNumber: state.shipping.phone_number,
    };
    let orderItems = []
    if (buyNowOrder) {
      orderItems = cart.filter(x => x.id === buyNowOrder)
    } else {
      orderItems = cart
    }
    orderItems = orderItems.map((item) => {
      console.log(item, "placed Order");
      return {
        name: item.name,
        price: item.price,
        quantity: item.amount,
        image: item.image,
        color: item.color,
        size: item.size,
        product: item.id.replace(item.color + item.size, ''),
      };
    });
    const paymentInfo = {
      id: 'COD',
      status: 'COD'
    };
    const razorPayPaymentId = localStorage.getItem('razorpay_payment_id')
    if (razorPayPaymentId) {
      paymentInfo["id"] = razorPayPaymentId
      paymentInfo["status"] = "Paid Online"
    }
    const body = {
      name: state.shipping.name,
      email: currentUser.email,
      shippingInfo,
      orderItems,
      paymentInfo,
      itemsPrice: finalAmount,
      shippingPrice: shipping_fee/100,
      totalPrice: finalAmount + shipping_fee/100,
    };
    try {
      await axios.post(create_order_url, body);
      toast.success('Order placed');
      fetchOrders(get_order_url);
    } catch (error) {
      toast.error(error.message);
    }
    finally {
      localStorage.removeItem('buy_now_order_id')
      localStorage.removeItem('razorpay_payment_id')
    }
  };

  const updateShipping = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_SHIPPING_DETAILS, payload: { name, value } });
  };

  useEffect(() => {
    fetchOrders(get_order_url);
    // eslint-disable-next-line
  }, [currentUser, cart]);

  return (
    <OrderContext.Provider value={{ ...state, updateShipping, placeOrder }}>
      {children}
    </OrderContext.Provider>
  );
};
// make sure use
export const useOrderContext = () => {
  return useContext(OrderContext);
};
