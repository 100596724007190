import React from "react";

import Wrapper from "./styles";
import { socialLinks, footerLinks } from "../../utils/constants";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";

const Footer2 = () => {
  return (
    <>
      <GoToTop />
      <div className="footer-area m-lrb-30px footer-2">
        <div className="footer-container">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                {/* Start single blog */}
                <div className="col-md-6 col-lg-3 mb-md-30px mb-lm-30px">
                  <div className="single-wedge">
                    <h1 style={{ color: "white" }}>SRKSTYLE</h1>
                    {/* <div class="footer-logo">
                              <a href="index.html"><img src="assets/images/logo/logo-white.png" alt=""></a>
                          </div> */}
                    <p className="about-text">
                      At Srk Style, we're all about infusing your wardrobe with
                      the timeless elegance and magnetic charm. From fashion
                      tips to trend analyses, join us as we redefine style, one
                      outfit at a time.
                    </p>
                    <ul className="link-follow">
                      <li>
                        <a className="m-0" title="Twitter" href="https://x.com/Srkstyle_com?t=NLz_T3J61BB1EUGI2uL8aQ&s=09" target="_blank">
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a title="Tumblr" href="https://t.me/Srkstyle" target="_blank">
                          <i className="fa fa-telegram" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a title="Facebook" href="https://www.facebook.com/Srkstyle?mibextid=ZbWKwL" target="_blank">
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a title="Instagram" href="https://www.instagram.com/srkstyle_com?igsh=ZWl2MHNhcHNwYmNv" target="_blank">
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End single blog */}
                {/* Start single blog */}
                <div className="col-md-3 col-sm-6 col-lg-3 mb-md-30px mb-lm-30px pl-lg-50px">
                  <div className="single-wedge">
                    <h4 className="footer-herading">Categories</h4>
                    <div className="footer-links">
                      <div className="footer-row">
                        <ul className="align-items-center">
                          <li className="li">
                            <Link className="single-link" to="products">
                              T-shirts
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="products">
                              Shirts
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="products">
                              Jeans
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="products">
                            Jacket 
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="products">
                            Hoodie
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="products">
                            Sweater 
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End single blog */}
                {/* Start single blog */}
                <div className="col-md-3 col-lg-3 col-sm-6 mb-lm-30px pl-lg-50px">
                  <div className="single-wedge">
                    <h4 className="footer-herading">Company</h4>
                    <div className="footer-links">
                      <div className="footer-row">
                        <ul className="align-items-center">
                          <li className="li">
                            <Link to="/Policy" className="single-link">
                              Privacy Policy
                            </Link>
                          </li>

                          <li className="li">
                            <Link
                              to="/termsandconditions"
                              className="single-link"
                            >
                              Terms and Conditions
                            </Link>
                          </li>
                          <li className="li">
                            <Link to="/shippingpolicy" className="single-link">
                              Shipping & Return Policy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="single-wedge">
                    <h4 className="footer-herading other-pages">Other Page</h4>
                    <div className="footer-links">
                      <div className="footer-row">
                        <ul className="align-items-center">
                          <li className="li">
                            <Link className="single-link" to="/about">
                              {" "}
                              About us{" "}
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="/contact">
                              Contact us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 col-lg-2 col-sm-6 mb-lm-30px pl-lg-50px">
                  <div className="single-wedge">
                    <h4 className="footer-herading">Other Page</h4>
                    <div className="footer-links">
                      <div className="footer-row">
                        <ul className="align-items-center">
                          <li className="li">
                            <Link className="single-link" to="/about">
                              {" "}
                              About us{" "}
                            </Link>
                          </li>
                          <li className="li">
                            <Link className="single-link" to="/contact">
                              Contact us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* End single blog */}
                {/* Start single blog */}

                {/* End single blog */}
                {/* Start single blog */}
                <div className="col-md-4 col-lg-3 col-sm-6">
                  <div className="single-wedge">
                    <h4 className="footer-herading">Store Information.</h4>
                    <div className="footer-links">
                      {/* News letter area */}
                      <p className="address">
                        Qila saray Aonla Bareilly. <br />
                      </p>
                      <p className="phone">
                        Phone: <a href="tel:+917409055341">+917409055341</a>
                      </p>
                      <p className="mail">
                        Email:{" "}
                        <a href="mailto:info@srkstyle.com ">
                          info@srkstyle.com{" "}
                        </a>
                      </p>
                      <img
                        src="assets/images/icons/payment.png"
                        alt=""
                        className="payment-img img-fulid"
                      />
                      {/* News letter area  End */}
                    </div>
                  </div>
                </div>
                {/* End single blog */}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="copy-text">
                    {" "}
                    © 2024 <strong>Srk Style</strong>, Maintaind by{" "}
                    <i className="fa fa-heart" aria-hidden="true" /> By{" "}
                    <a className="company-name" href="https://www.napps.in/">
                      <strong>napps.in</strong>
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer2;
