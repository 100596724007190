import React from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Category = () => {
  const history = useHistory();

  const handleSubmit = (make) => {
    const queryParams = {};
    console.log(make, "make");
    if (make?.length > 0) {
      queryParams["category"] = make;
    }
    const searchParams = new URLSearchParams(queryParams).toString();
    console.log("hello");
    history.push({
      pathname: "/products",
      search: `?${searchParams}`,
    });
  };

  const sliderSettings = {
    autoplay: true,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // adjust the breakpoint as needed for mobile screens
        settings: {
          slidesToShow: 3, // Show 4 slides on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="banner-area container">
        <div className="category-slider row m-0">
          <Slider {...sliderSettings}>
            <div className="col-4 col-lg-3 padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/new-arrivals.png?updatedAt=1717337847803"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">New Arrivals</h4>
                  <div
                    onClick={() => handleSubmit("new-arrivals")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 center-col mb-md-30px mb-lm-30px padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/cate-shirt.jpg?updatedAt=1717337217884"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">Shirts</h4>
                  <div
                    onClick={() => handleSubmit("shirts")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4 col-lg-3 mb-lm-30px padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/cate-t-shirts.jpg?updatedAt=1717337217905"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">T-shirts</h4>
                  <div
                    onClick={() => handleSubmit("t-shirts")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 mb-md-30px mb-lm-30px padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/categ-jeans.jpg?updatedAt=1717337400809"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">Jeans</h4>
                  <div
                    onClick={() => handleSubmit("jeans")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/cate-hoddy.jpg?updatedAt=1717337217787"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">Hoodie</h4>
                  <div
                    onClick={() => handleSubmit("hoodie")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/cate-sweater.jpg?updatedAt=1717337217774"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">Sweater</h4>
                  <div
                    onClick={() => handleSubmit("sweater")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 padding-zero">
              <div className="category">
                <img
                  src="https://ik.imagekit.io/i4uldqtxs/cate-jacket.jpg?updatedAt=1717337217873"
                  alt=""
                />
                <div className="item-disc">
                  <h4 className="title-2">Jacket</h4>
                  <div
                    onClick={() => handleSubmit("jacket")}
                    className="shop-link btn btn-primary"
                  >
                    Buy Now{" "}
                    <i
                      className="fa fa-shopping-basket ml-5px"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="hide-in-desktop"></div>
    </>
  );
};
export default Category;
