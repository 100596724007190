import styled from 'styled-components';

const Wrapper = styled.section`
  background: whitesmoke;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  align-items: center;

  color: var(--clr-primary-1);
  a {
    color: #000;
    padding: 0.5rem;
    transition: var(--transition);
    text-decoration: none;
    font-size: 20px;
    font-family: 'Nunito Sans';
  }
  h3 {
    font-size: 22px;
  }
  a:hover {
    color: var(--clr-primary-1);
  }
`;

export default Wrapper;
