import React from 'react';
import { useFilterContext } from '../../context/filter_context';
import { BsFillGridFill, BsList } from 'react-icons/bs';
import Wrapper from './styles';
import Form from 'react-bootstrap/Form';

const Sort = () => {
  const {
    filtered_products: products,
    grid_view,
    sort,
    setGridView,
    setListView,
    updateSort,
  } = useFilterContext();

  return (
    <Wrapper>
      <div className='btn-container ' >
        <button
          type='button'
          className={`${grid_view ? 'active' : null}`}
          onClick={setGridView}
        >
          <BsFillGridFill />
        </button>
        {/* <button
          type='button'
          className={`${!grid_view ? 'active' : null}`}
          onClick={setListView}
        >
          <BsList />
        </button> */}
      </div>
      {/* <div className='shop-top-bar d-flex'> */}
      <p>{products.length} products found</p>
      <hr />
      <form className=''>
        <label htmlFor='sort'>sort by</label>
        <select
          name='sort'
          id='sort'
          className='sort-input'
          value={sort}
          onChange={updateSort}
        >

          <option value='price-lowest'>price (lowest)</option>
          <option value='price-highest'>price (highest)</option>
          <option value='name-a'>name (A-Z)</option>
          <option value='name-z'>name (Z-A)</option>

        </select>

      </form>
      {/* </div> */}
      {/* <div className="shop-top-bar d-flex">
        Left Side start
        <p>
          <span>12</span> Product Found of <span>30</span>
        </p>
        Left Side End
        <div className="shop-tab nav">
          <a className="active" href="#shop-grid" data-bs-toggle="tab">
            <i className="fa fa-th" aria-hidden="true" />
          </a>
          <a href="#shop-list" data-bs-toggle="tab">
            <i className="fa fa-list" aria-hidden="true" />
          </a>
        </div>
        Right Side Start
        <div className="select-shoing-wrap d-flex align-items-center">
          <div className="shot-product">
            <p>Sort By:</p>
          </div>
          <div className="shop-select">
            <select className="shop-sort">
              <option data-display="Relevance">Relevance</option>
              <option value={1}> Name, A to Z</option>
              <option value={2}> Name, Z to A</option>
              <option value={3}> Price, low to high</option>
              <option value={4}> Price, high to low</option>
            </select>
          </div>
        </div>
        Right Side End
      </div> */}

    </Wrapper>
  );
};

export default Sort;
