import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Wrapper from "./styles";
import { useProductsContext } from "../../context/products_context";
import { useUserContext } from "../../context/user_context";
import ReviewStars from "../ReviewStars/";
import Button from "../Button";
import Modal from "react-bootstrap/Modal";

function ReviewModal({ product }) {
  const { _id: id, reviews } = product;
  const { reviewProduct } = useProductsContext();
  const { currentUser } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [stars, setStars] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async () => {
    setLoading(true);
    const { success, message } = await reviewProduct(id, stars, comment);
    setLoading(false);
    if (success) {
      return toast.success(message);
    } else {
      return toast.error(message);
    }
  };

  const updateStars = (number) => {
    setStars(number);
  };

  const handleOpen = () => {
    console.log("hello anas", isOpen);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (reviews && currentUser) {
      const userReview = reviews.find((rev) => rev.email === currentUser.email);
      if (userReview) {
        setStars(userReview.rating);
        setComment(userReview.comment);
      }
    }
    // eslint-disable-next-line
  }, [product, currentUser]);

  return (
    <Wrapper>
      {/* <button className='shop-link btn btn-primary' style={{zIndex:-1,height:'40px'}} onClick={() => handleOpen()}>
        review product
      </button> */}
      <div>
        <p onClick={handleShow} className="review-product">
          Review Product
        </p>
      </div>
      <div className={`${isOpen ? "modal" : "modal"}`}></div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Review Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="form">
            <label className="mt-1">Ratings</label>
            <ReviewStars stars={stars} updateStars={updateStars} />
            <label className="mt-2">Enter your comment</label>
            <textarea
              className="input mt-1"
              style={{ borderRadius: "8px" }}
              placeholder="your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {currentUser ? (
              <div className="btn-container review-btn-container">
                <Button
                  disabled={loading}
                  className="review-btn"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <button className="review-btn" onClick={handleClose}>
                  cancel
                </button>
              </div>
            ) : (
              <div className="btn-container">
                <Link to="/login" className="btn">
                  login
                </Link>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
}

export default ReviewModal;
