import React, { useEffect } from "react";
import Wrapper from "./styles";
import { useCartContext } from "../../context/cart_context";
import { Link } from "react-router-dom";
import AmountButtons from "../../components/AmountButtons";
import CartTotals from "../../components/CartTotals";
import { useUserContext } from "../../context/user_context";

const NewCart = ({ id, name, color, price, amount, size, onClose }) => {
  const { removeItem, cart, toggleAmount } = useCartContext();
  const { currentUser } = useUserContext();

  const increase = (id) => {
    toggleAmount(id, "inc");
  };

  const placeholderImage =
    "https://images.unsplash.com/photo-1597484661973-ee6cd0b6482c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";

  const decrease = (id) => {
    toggleAmount(id, "dec");
  };

  console.log("product sidebar", cart);

  useEffect(() => {
    document.title = "SRK STYLE  | Cart";
  }, []);

  if (cart.length < 1) {
    return (
      <Wrapper className="page-100" style={{ height: "100vh" }}>
        <div className="empty">
          <h2>Your cart is empty</h2>
          <Link
            to="/products"
            className="btn submit-btn"
            style={{ margin: "30px",maxWidth:'300px'  }}
            onClick={onClose}
          >
            fill it
          </Link>
        </div>
      </Wrapper>
    );
  }

  return (
    <>
      <main
        style={{
          padding: "0px 21px 0px 21px",
          height: "100vh",
          overflow: "auto",
        }}
      >
        {/* <PageHero title='cart' /> */}
        <div className="cart_close">
          <div className="cart_text">
            <h3>cart</h3>
            <i className="fa fa-close" onClick={onClose} />
          </div>
          <div className="mini_cart_close">
            <a
              href="javascript:void(0)"
              style={{ textDecoration: "none", color: "#000" }}
            >
              <i className="icon-close icons" onClick={onClose} />
            </a>
          </div>
        </div>
        <div
          className="cart_gallery"
          style={cart.length > 5 ? { height: "50vh", overflowY: "scroll" } : {}}
        >
          {cart.map((item, index) => (
            <div className="cart_item">
              <div className="cart_img">
                {/* <a href="#" style={{ textDecoration: "none", color: "000" }}>
                <img src={item.image ? item.image : placeholderImage} alt="" />
              </a> */}
              </div>

              <div className="cart_info">
                <a href="#" style={{ textDecoration: "none", color: "000" }}>
                  <img
                    src={item.image ? (item.image?.url ? item.image.url : item.image) : placeholderImage}
                    alt=""
                    width={40}
                    height={40}
                  /><br />
                  <div style={{ fontSize: '0.5rem', color: 'black', padding: '6px -5px 16px -1px' }}>₹ {item.price}/per item</div>
                </a>
                <AmountButtons
                  amount={item.amount}
                  increase={() => increase(item.id)}
                  decrease={() => decrease(item.id)}
                  style={{ zoom: "0.6" }}
                />

                <div>
                  <a href="#" style={{ textDecoration: "none", color: "#000" }}>
                    {item.name}
                  </a>
                  <p
                    style={{
                      marginBottom: "10px",
                      fontSize: "12px",
                      textAlign: "left",
                      color: "#fb5d5d",
                    }}
                  >
                    {item.size}
                  </p>
                </div>
                <div
                  className="cart_remove"
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  <a href="#" style={{ textDecoration: "none", color: "#000" }}>
                    <i
                      className="fa fa-trash"
                      onClick={() => removeItem(item.id)}
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="mini_cart_table">
        <div className="cart_table_border">
          <div className="cart_total">
            <span>Sub total:</span>
            <span className="price">$125.00</span>
          </div>
          <div className="cart_total mt-10">
            <span>total:</span>
            <span className="price">$125.00</span>
          </div>
        </div>
      </div> */}
        <div className="cart_button" style={{ marginTop: "20px" }}>
          <Link
            to="/cart"
            style={{
              textDecoration: "none",
              color: "#000",
              border: "2px solid silver",
              padding: "2px 10px",
              borderRadius: "8px",
              fontSize: "12px",
            }}
          >
            <i className="fa fa-shopping-cart" /> View cart
          </Link>
        </div>
        <div style={{ zoom: "0.9" }}>
          <CartTotals isCheckoutBtnShown={false} />
        </div>
      </main>
      <div>
        {currentUser ? (
          <Link
            to="/checkout"
            onClick={onClose}
            className="btn proceed-btn"
            style={{ marginBottom: "20px", border: "1px solid silver" }}
          >
            proceed to checkout
          </Link>
        ) : (
          <Link
            to="/login"
            className="btn proceed-btn"
            onClick={onClose}
            style={{ marginBottom: "20px", border: "1px solid silver" }}
          >
            Login to order
          </Link>
        )}
      </div>
    </>
  );
};

export default NewCart;
