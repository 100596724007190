import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Sidebar, Footer, Toast, ErrorBoundary } from './components';
import Navbar from './components/Navbar/Navbar'
import { useProductsContext } from './context/products_context';
import 'react-toastify/dist/ReactToastify.css';
import {
  Home,
  About,
  Products,
  Cart,
  SingleProduct,
  Checkout,
  Error,
  Login,
  Register,
  Forgot,
  Reset,
  OrdersPage,
  PrivateRoute,
  ProfilePage,
} from './pages';
import HomePage2 from './pages/HomePage2';
import HomePage3 from './pages/HomePage3/Homepage';
import Footer2 from './components/Footer/Footer2';
import Policy from './pages/HomePage3/components/Policy';
import Termsandcondition from './pages/HomePage3/components/Termsandconditions';
import Shippingpolicy from './pages/HomePage3/components/Shippingpolicy';
import Loginregister from './pages/HomePage3/components/Loginregister';
import Mobilemenu from './pages/HomePage3/components/mobilemenu';
import Productscard from './pages/HomePage3/components/Productscard';
import Contact from './components/Contact';
import GoToTop from './GoToTop';
import ScrollToTop from "react-scroll-to-top";



function App() {
  const { isSidebarOpen } = useProductsContext();
  const overflowPropertyToHideScroll =
    isSidebarOpen === true ? 'hidden' : 'scroll';

  return (
    <div className='body-height'>
      <Router>
        <Toast />
        <ScrollToTop smooth />
        <Navbar />
        <Sidebar />
        <ErrorBoundary>
          <Switch>
            <Route exact path='/'>
              <HomePage3 />
            </Route>
            <Route exact path='/about'>
              <About />
            </Route>
            <Route exact path='/contact'>
              <Contact />
            </Route>
            <Route exact path='/products'>
              <Products />
            </Route>
            <Route exact path='/Productscard'>
              <Productscard />
            </Route>
            {/* <Route exact path='/Category'>
              <Products />
            </Route> */}
            <Route exact path='/login'>
              <Loginregister />
            </Route>
            <Route exact path='/cart'>
              <Cart />
            </Route>
            <Route exact path='/menu'>
              <Mobilemenu />
            </Route>
            <PrivateRoute exact path='/login'>
              <Login />
            </PrivateRoute>
            <Route exact path='/register'>
              <Register />
            </Route>
            <Route exact path='/forgot-password'>
              <Forgot />
            </Route>
            <PrivateRoute exact path='/reset-password'>
              <Reset />
            </PrivateRoute>
            <Route exact path='/products/:id' children={<SingleProduct />} />
            <PrivateRoute exact path='/checkout'>
              <Checkout />
            </PrivateRoute>
            <PrivateRoute exact path='/orders'>
              <OrdersPage />
            </PrivateRoute>
            <PrivateRoute exact path='/profile'>
              <ProfilePage />
            </PrivateRoute>
            {/* <PrivateRoute exact path='/policy'>
              <Policy />
            </PrivateRoute> */}
            <Route exact path="/policy" >
              <Policy />
            </Route>

            <Route exact path="/termsandconditions" >
              <Termsandcondition />
            </Route>
            <Route exact path="/Shippingpolicy" >
              <Shippingpolicy />
            </Route>

            <Route exact path='*'>
              <Error />
            </Route>
          </Switch>
        </ErrorBoundary>
        <Footer2 />
      </Router>
    </div>
  );
}

export default App;
