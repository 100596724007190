import React from 'react';


const Shippingpolicy = () => {
    return (
        <>

            <div className='container'>
                <div className='heading'>
                    <h4>
                        Shipping & Return Policy
                    </h4>
                </div>
                
                <p><b> What are the shipping charges?</b></p>

                    <p>Shipping charges are determined based on the order range and are calculated according to the distance from the customer's location to the SRK Styles facility. For deliveries within India, shipping charges will be applicable based on the respective pin codes. The specific charges for your location will be displayed during the checkout process.</p>

                 <p> <b>How will the delivery be done?</b></p>

                  <p>  We offer nationwide delivery across India, and we determine shipping charges based on applicable pin codes. All deliveries are processed through our reliable network of courier services to ensure the safe and timely arrival of your order.</p>

               <p><b>How are items packaged?</b></p>

                   <p> All items are packaged securely for nationwide delivery. Your order will be sent in environmentally friendly packaging, contributing to our commitment to sustainability and ensuring that the products reach you in optimal condition.</p>


                  <p> <b> Return Policy:</b></p>

                  <p>  We understand that sometimes returns are necessary. If you wish to return a product, a return fee of INR 50 will be applicable. Please follow the guidelines below for a smooth return process:</p>

                    <p><b>Initiate the Return:</b></p>

                   <p> Log in to your SRK Styles account.
                    Navigate to the 'Order History' section.
                    Select the order containing the item you wish to return.
                    Click on the 'Return Item' option.
                    Packaging:</p>

                   <p> Ensure that the item is in its original packaging, unworn, and in a resalable condition.
                    Include all tags, labels, and accessories that came with the product.
                    Return Process:</p>

                    <p>  After initiating the return, our team will review the request.
                    Once approved, you will receive instructions on how to return the item.
                    Shipping the Return:</p>

                    <p> Pack the item securely and affix the provided return label.
                    Drop off the package at the designated courier service.
                    Return Fee:</p>

                    <p>A return fee of INR 50 will be deducted from the refund amount.
                    The fee helps cover the costs associated with processing and managing returns.
                    Refund Process:</p>

                    <p> Upon receiving the returned item, our team will inspect it.
                    Once the inspection is complete, a refund will be initiated.
                    The refunded amount will exclude the return fee.
                    Please note that this return policy is applicable to eligible items only.
                    Items that do not meet the specified conditions may not be accepted for return.
                    For further assistance or inquiries, feel free to contact our customer service team at hello.srkstyles@gmail.com.</p>
                
            </div>
        </>
    )
}
export default Shippingpolicy;