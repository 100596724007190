import React, { useEffect } from 'react';
import Slider from "react-slick";
import Banners from './components/Banners';
import Singleitems from './components/Singleitems';
import Allproducts from './components/Allproducts';
import Productscard from './components/Productscard';
import Newarrivals from './components/Newarrivals';
import Category from './components/category';



const HomePage3 = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 400,
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false
            }
        }]
    };
    useEffect(() => {
        document.title = 'SRK Styles ';
    }, []);

    return (<>

        <Banners />
        <Category />
        
        {/* <Allproducts />
        <Productscard /> */}
        <br/>
        <Newarrivals />
        <Singleitems />
        
    </>)
}

export default HomePage3;