// GoToTop.js (your hook file)
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoToTop = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    console.log('yes22')
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {

    setTimeout(function () {
      console.log('yes')
      document.body.scrollTop = 0; // scrolls to top
    }, 2000);
  }, [location]);

  return null; // This component doesn't need to render anything
};

export default GoToTop;
