import React from 'react';
import Drawer from 'react-modern-drawer'
import { Link, useHistory, useLocation } from 'react-router-dom';
import NewCart from '../../pages/CartPage/NewCart';

const CardDrawer = (props) => {
    console.log("hello props", props);
    const handleCloseDrawer = (props) => {
        props?.isOpenCart(false)
    }

    return (
        <div>

            <Drawer
                open={props.isOpenCart}
                onClose={props.handleCloseCartDrawer}
                direction='right'
                className='bla bla bla drawer'
            >
                <div className='empty'>
                    {/* <h2>Your cart is empty</h2>
                    <Link to='/products'
                        onClick={props.handleCloseCartDrawer}
                        className='btn fill-it '>
                        fill it
                    </Link> */}
                    <NewCart onClose={props.handleCloseCartDrawer}/>
                </div>
                {/* <div className='itemcart'>
                    <div className='carthead'>
                        <span className='title'>Cart</span>
                    </div>
                    <div className='cartbody customScroll'>
                        <ul className='minicart-list'>
                            <li>
                                <Link to="single-product.html" className="image"><img src='https://craftsnippets.com/articles_images/placeholder/placeholder.jpg' alt="Cart product Image"></img>
                                </Link>
                                <div className='cart-content'>
                                    <Link to="ingle-product.html" className="cart-title">Women's Elizabeth Coat</Link>
                                    <span class="amount">₹18.86</span>
                                    {/* <a href="#" class="remove">×</a> */}
                {/* </div>
                            </li>
                            <li>
                                <Link to="single-product.html" className="image"><img src='https://craftsnippets.com/articles_images/placeholder/placeholder.jpg' alt="Cart product Image"></img>
                                </Link>
                                <div className='cart-content'>
                                    <Link to="ingle-product.html" className="cart-title">Women's Elizabeth Coat</Link>
                                    <span class="amount">₹18.86</span>
                                    {/* <a href="#" class="remove">×</a> */}
                {/* </div>
                            </li>
                            <li>
                                <Link to="single-product.html" className="image"><img src='https://craftsnippets.com/articles_images/placeholder/placeholder.jpg' alt="Cart product Image"></img>
                                </Link>
                                <div className='cart-content'>
                                    <Link to="ingle-product.html" className="cart-title">Women's Elizabeth Coat</Link>
                                    <span class="amount">₹18.86</span> */}
                {/* <a href="#" class="remove">×</a> */}
                {/* </div>
                            </li>
                        </ul>
                    </div>
                    <div className='foot'>
                        <div className='buttons mt-30px color: #fb5d5d; ' >
                            <a href="cart.html" class="btn btn-dark btn-hover-primary mb-30px">view cart</a>
                            <a href="checkout.html" class="btn btn-outline-dark current-btn">checkout</a>
                        </div>
                    </div>
                </div> */}
            </Drawer>
        </div>

    )
}

export default CardDrawer;