import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Banners = () => {
  const sliderSettings = {
    autoplay: true,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="section ">
      <div className="container-fluid">
        <Slider {...sliderSettings}>
          <div>
            <img
              src="https://ik.imagekit.io/i4uldqtxs/srk-banner-2.png?updatedAt=1719083323439"
              width="100%"
            />
          </div>
          <div>
            <img
              src="https://ik.imagekit.io/i4uldqtxs/srk-banner-1.png?updatedAt=1719083322688"
              width="100%"
            />
          </div>
          {/* <div>
            <img
              src="https://images.bewakoof.com/uploads/category/mobilesite/Birthday-Bash_Inside_Msite-Banner_Common_(2)_(1)-1711951484.jpg"
              width="100%"
            />
          </div>
          <div>
            <img
              src="https://bewakoof-tawny.vercel.app/img/Nov-COTM-SnazzyGreen-Bewakoof-HeroBanner-Launch-men--1--1635685347.webp"
              width="100%"
            />
          </div>
          <div>
            <img
              src="https://smdanishiqbal.github.io/HyperBazaar/images/cat3.jpg"
              width="100%"
            />
          </div> */}
          {/* <div>hello</div> */}
        </Slider>
      </div>
      <div className="categories-section">
        <div className="container mt-5">
          <div className="col-lg col-md col-12">
            <div className="section-title mb-0">
              <h2 className="title">#Categories</h2>
              <br />
              <br />
            </div>
          </div>
        </div>
        {/* <div
          className="d-flex justify-content-between"
          style={{ padding: "20px", gap: "10px" }}
        >
          <div className="col-md-3 col-sm-3 col-xs-3">
            <Link to="/products" style={{ textDecoration: "none" }}>
              <div className="category-bg-image-1">
                <p>Jeans</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-3">
            <Link to="/products" style={{ textDecoration: "none" }}>
              <div className="category-bg-image-2">
                <p>Shirts</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-3">
            <Link to="/products" style={{ textDecoration: "none" }}>
              <div className="category-bg-image-3">
                <p>New Arrivals</p>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-sm-3 col-xs-3">
            <Link to="/products" style={{ textDecoration: "none" }}>
              <div className="category-bg-image-4">
                <p>Oversized T-shirts</p>
              </div>
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Banners;
