import React, { useState, useEffect } from 'react';
import Wrapper from './styles';
import { useUserContext } from '../../context/user_context';
import { Link, useHistory } from 'react-router-dom';
import useMounted from '../../hooks/useMounted';
import { toast } from 'react-toastify';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import Button from '../../components/Button';
import { Stepper, Step } from 'react-form-stepper';
import { signup_url } from '../../utils/constants';
import axios from 'axios';

function RegisterPage() {
  const history = useHistory();
  const mounted = useMounted();
  const { registerUser, signInWithGoogle } = useUserContext();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error('Please enter e-mail');
    }

    if (!password) {
      return toast.error('Please enter password');
    }

    if (password !== confirmPassword) {
      return toast.error("Passwords didn't match");
    }

    setIsSubmitting(true);
    registerUser(email, password)
      .then((res) => {
        history.push('/')
        console.log(res, "res");
      })
      .catch((err) => {
        toast.error(`Error: ${err.message}`);
      })
      .finally(() => mounted.current && setIsSubmitting(false));
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  function togglePasswordVisibility() {
    setIsVisiblePassword(!isVisiblePassword);
  }

  function toggleConfirmPasswordVisibility() {
    setIsVisibleConfirmPassword(!isVisibleConfirmPassword);
  }

  useEffect(() => {
    document.title = 'Register';
  }, []);
  console.log(activeStep, "activeStep");
  const handleRegister = (e) => {
    e.preventDefault()
    const obj = {
      "name": firstName + lastName,
      "phonenumber": phoneNumber,
      "email": email,
      "signInType": 'google/email'
    };
    if (firstName.length === 0) {
      return toast.error('Fullname is required.')
    }
    if (lastName.length === 0) {
      return toast.error('Subject is required.')
    }
    if (email.length === 0) {
      return toast.error('Email is required.')
    }
    if (phoneNumber.length === 0) {
      return toast.error('Number is required.')
    }

    setLoading(true)
    axios.post(`${signup_url}`, obj)
      .then((res) => {
        console.log("hello anas");
        if (res.data.error) {
          toast.error(res.data.error)
        }
        if (res.data.success === true) {
          console.log(res, "resgister data");
          toast.success(res.data.message);
          setEmail("");
          setFirstName("");
          setLastName("");
          setPhoneNumber("");
          handleNext();
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false)
      });
  }


  return (
    <Wrapper className='register-pd'>

      <div>
        <div className='title'>
          <h2>Register</h2>
        </div>
        <Stepper activeStep={activeStep}>
          <Step label="Step 1" />
          <Step label="Step 2" />
        </Stepper>
        {activeStep === 1 &&
          <form onSubmit={handleSubmit}>
            <div className='form-control email'>
              <input
                type='email'
                name='email'
                className='input'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* end email */}
            {/* pass */}
            <div className='form-control password'>
              <input
                type={!isVisiblePassword ? 'password' : 'text'}
                name='password'
                className='input'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div onClick={togglePasswordVisibility} className='togglebtn'>
                {!isVisiblePassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
              </div>
            </div>
            <div className='form-control password'>
              <input
                type={!isVisibleConfirmPassword ? 'password' : 'text'}
                name='confirmPassword'
                className='input'
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <div
                onClick={toggleConfirmPasswordVisibility}
                className='togglebtn'
              >
                {!isVisibleConfirmPassword ? (
                  <BsFillEyeSlashFill />
                ) : (
                  <BsFillEyeFill />
                )}
              </div>
            </div>
            {/* end pass */}
            <Button
              type='submit'
              className='register-button'
              disabled={isSubmitting}
            >
              Register
            </Button>
            <div className='seperator'>
              <hr />
              <span>or</span>
            </div>
            <button
              type='button'
              className='google-button'
              disabled={isSubmitting}
              onClick={() => {
                signInWithGoogle()
                  .then((user) => {
                    handleNext();
                    history.push('/');
                  })
                  .catch((err) => {
                    toast.error(`Error: ${err.message}`);
                  });
              }}
            >
              <i
                className="icon fa fa-google"
                style={{ padding: "5px 12px 0px" }}
              />
              Sign in with Google
            </button>
          </form>
        }
        {activeStep === 0 &&
          <form>
            <div className='form-control email'>
              <input
                type='test'
                name={firstName}
                className='input'
                placeholder='Enter first name'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='form-control email'>

              <input
                type='test'
                name={lastName}
                className='input'
                placeholder='Enter last name'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className='form-control email'>
              <input
                type='email'
                name='email'
                className='input'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='form-control email'>
              <input
                type='number'
                name={phoneNumber}
                className='input'
                placeholder='Enter mobile number'
                value={phoneNumber}
                // onChange={(e) => setPhoneNumber(e.target.value)}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setPhoneNumber(e.target.value);
                }}
              />
            </div>
            <Button
              type='submit'
              className='register-button'
              onClick={(e) => handleRegister(e)}
            >
              Next
            </Button>
          </form>
        }
      </div>
    </Wrapper>
  );
}

export default RegisterPage;
