import React from "react";
import { Link } from "react-router-dom";

const Productscard = () => {
  return (
    <>
      <div className="container">
        <div className="row new ">
          <div className="col">
            <div className="tab-content">
              {/* 1st tab start */}
              <div className="tab-pane fade show active" id="tab-product--all">
                <div className="row">
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-3 col-xs-3 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <h5 className="title">
                          <a >
                            Women's Elizabeth Coat
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-10%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Ardene Microfiber Tights
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹48.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-7%</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "90%" }} />
                          </span>
                          <span className="rating-num">( 4.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Long Sleeve Shirts
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                          <span className="old">₹38.00</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "70%" }} />
                          </span>
                          <span className="rating-num">( 3.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Parrera Sunglasses - Lomashop
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-5%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Cool Man Wearing Leather
                          </a>
                        </h5>
                        <span className="price">
                          <span className="new">₹38.50</span>
                          <span className="old">₹40.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Portrait Of A Young Stylish
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Men's Fashion Leather Bag
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 "
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "60%" }} />
                          </span>
                          <span className="rating-num">( 3 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Long sleeve knee length
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                </div>
              </div>
              {/* 1st tab end */}
              {/* 2nd tab start */}
              <div className="tab-pane fade" id="tab-product--new">
                <div className="row">
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/1.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Elizabeth Coat
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/3.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-10%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Ardene Microfiber Tights
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹48.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/5.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-7%</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "90%" }} />
                          </span>
                          <span className="rating-num">( 4.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Long Sleeve Shirts
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                          <span className="old">₹38.00</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/7.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "70%" }} />
                          </span>
                          <span className="rating-num">( 3.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Parrera Sunglasses - Lomashop
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/10.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-5%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Cool Man Wearing Leather
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹40.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/12.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Portrait Of A Young Stylish
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/14.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Men's Fashion Leather Bag
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 "
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/16.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "60%" }} />
                          </span>
                          <span className="rating-num">( 3 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Long sleeve knee length
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                </div>
              </div>
              {/* 2nd tab end */}
              {/* 3rd tab start */}
              <div className="tab-pane fade" id="tab-product-men">
                <div className="row">
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/1.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Elizabeth Coat
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/3.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-10%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Ardene Microfiber Tights
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹48.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/5.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-7%</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "90%" }} />
                          </span>
                          <span className="rating-num">( 4.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Long Sleeve Shirts
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                          <span className="old">₹38.00</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/7.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "70%" }} />
                          </span>
                          <span className="rating-num">( 3.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Parrera Sunglasses - Lomashop
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/10.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-5%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Cool Man Wearing Leather
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹40.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/12.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Portrait Of A Young Stylish
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/14.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Men's Fashion Leather Bag
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 "
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/16.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "60%" }} />
                          </span>
                          <span className="rating-num">( 3 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Long sleeve knee length
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                </div>
              </div>
              {/* 3rd tab end */}
              {/* 4th tab start */}
              <div className="tab-pane fade" id="tab-product-women">
                <div className="row">
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/1.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Elizabeth Coat
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/3.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-10%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Ardene Microfiber Tights
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹48.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/5.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-7%</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "90%" }} />
                          </span>
                          <span className="rating-num">( 4.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Long Sleeve Shirts
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                          <span className="old">₹38.00</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/7.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "70%" }} />
                          </span>
                          <span className="rating-num">( 3.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Parrera Sunglasses - Lomashop
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/10.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-5%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Cool Man Wearing Leather
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹40.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/12.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Portrait Of A Young Stylish
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/14.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Men's Fashion Leather Bag
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 "
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/16.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "60%" }} />
                          </span>
                          <span className="rating-num">( 3 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Long sleeve knee length
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                </div>
              </div>
              {/* 4th tab end */}
              {/* 5th tab start */}
              <div className="tab-pane fade" id="tab-product-kids">
                <div className="row">
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/1.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Elizabeth Coat
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/3.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-10%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Ardene Microfiber Tights
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹48.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/5.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-7%</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "90%" }} />
                          </span>
                          <span className="rating-num">( 4.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Women's Long Sleeve Shirts
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                          <span className="old">₹38.00</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/7.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "70%" }} />
                          </span>
                          <span className="rating-num">( 3.5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Parrera Sunglasses - Lomashop
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/2.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/10.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="sale">-5%</span>
                          <span className="new">New</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Cool Man Wearing Leather
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                          <span className="old">₹40.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/4.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/12.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "100%" }} />
                          </span>
                          <span className="rating-num">( 5 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Portrait Of A Young Stylish
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/6.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/14.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges"></span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "80%" }} />
                          </span>
                          <span className="rating-num">( 4 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Men's Fashion Leather Bag
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹30.50</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6 "
                    data-aos="fade-up"
                    data-aos-delay={800}
                  >
                    {/* Single Prodect */}
                    <div className="product">
                      <div className="thumb">
                        <a href="single-product.html" className="image">
                          <img
                            src="assets/images/product-image/8.jpg"
                            alt="Product"
                          />
                          <img
                            className="hover-image"
                            src="assets/images/product-image/16.jpg"
                            alt="Product"
                          />
                        </a>
                        <span className="badges">
                          <span className="new">Sale</span>
                        </span>
                        <div className="actions">
                          <a
                            href="wishlist.html"
                            className="action wishlist"
                            title="Wishlist"
                          >
                            <i className="pe-7s-like" />
                          </a>
                          <a
                            href="#"
                            className="action quickview"
                            data-link-action="quickview"
                            title="Quick view"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="pe-7s-search" />
                          </a>
                          <a
                            href="compare.html"
                            className="action compare"
                            title="Compare"
                          >
                            <i className="pe-7s-refresh-2" />
                          </a>
                        </div>
                        <button title="Add To Cart" className=" add-to-cart">
                          Add To Cart
                        </button>
                      </div>
                      <div className="content">
                        <span className="ratings">
                          <span className="rating-wrap">
                            <span className="star" style={{ width: "60%" }} />
                          </span>
                          <span className="rating-num">( 3 Review )</span>
                        </span>
                        <h5 className="title">
                          <a href="single-product.html">
                            Long sleeve knee length
                          </a>
                        </h5>
                        <span className="price">
                          <span className="">₹38.50</span>
                        </span>
                      </div>
                    </div>
                    {/* Single Prodect */}
                  </div>
                </div>
              </div>
              {/* 5th tab end */}
            </div>
            <div className="load-more ">
              <Link to="/products" className="load-more-btn btn-hover-dark ">
                
                  {" "}
                  See More{" "}
                  <i className="fa fa-arrow-right ml-15px" aria-hidden="true" />
                
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Productscard;
